<script setup>
const styleStore = useStyleStore()
</script>

<template>
  <div :class="{ 'dark': styleStore.darkMode, 'min-h-screen': true }">
    <Header hide-menu />
    <div
      class="prose max-w-none bg-pumice-50 dark:bg-nickel-800 dark:text-nickel-100 min-h-screen w-screen pt-14 lg:w-auto"
    >
      <slot />
    </div>
    <Footer />
  </div>
</template>
